
<div class="all module">
    <div class="language">
        <select #langSelect (change)="translate.use(langSelect.value)">
            <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">
                {{ lang}}
            </option>
        </select>
    </div>
    <div class="header">
        <div class="parallax">
            <div>
                <img class="header1" src="assets/img/header/header1.svg" alt="header1" />
                <img class="header2 other-img" src="assets/img/header/header2.svg" alt="header2" />
                <img class="header3 other-img" src="assets/img/header/header3.svg" alt="header3" />
                <img class="header4 other-img" src="assets/img/header/header4.svg" alt="header4" />
                <img class="header5 other-img" src="assets/img/header/header5.svg" alt="header5" />
                <img class="header6 other-img" src="assets/img/header/header10.svg" alt="header6" />
                <img class="header7 other-img" src="assets/img/header/header7.svg" alt="header7" />
                <img class="header8 other-img" src="assets/img/header/header8.svg" alt="header8" />
                <img class="header9 other-img" src="assets/img/header/header9.svg" alt="header9" />
                <img class="header10 other-img" src="assets/img/header/header12.svg" alt="header10" />
                <img class="header11 other-img" src="assets/img/header/header11.svg" alt="header11" />
            </div>
            <div class="general-information">
                <div class="section-img">
                    <img src="assets/img/me.png" alt="me" />
                </div>
                <div class="section-name">
                    <h1>{{ 'GENERAL_INFORMATION.NAME' | translate }}<span>.</span></h1>
                    <p>{{ 'GENERAL_INFORMATION.PARAGRAPH' | translate }}</p>
                </div>
                <div class="icon-list">
                    <a href="https://github.com/selinoykunergiz/" target="_blank">
                        <fa-icon class="common-icon" [icon]="['fab', 'github']"></fa-icon>
                    </a>
                    <a href="https://www.linkedin.com/in/selinoykunergiz" target="_blank">
                        <fa-icon class="common-icon" [icon]="['fab', 'linkedin-in']"></fa-icon>
                    </a>
                    <a href="mailto:selinoykunergiz@gmail.com" target="_blank">
                        <fa-icon class="common-icon" [icon]="['fab', 'mailchimp']"></fa-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="content">
        <div class="about-me">
            <div class="ab-title">
                <img class="aboutme-img" src="assets/img/content/dots-bg-light.svg" alt="content" />
                <h2 class="aboutme-title">{{ 'ABOUTME.TITLE' | translate }}</h2>
            </div>
            <div class="content-detail">
                <div class="icon">
                    <fa-icon class="common-icon content-icon" [icon]="faHome"></fa-icon>
                </div>
                <div class="section-content">
                    <p class="paragraph">{{ 'ABOUTME.PARAGRAPH' | translate }}</p>
                    <div class="section-btn">
                        <button class="btn btn-default" (click)="downloadCv()">{{ 'ABOUTME.CV' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="education">
            <div class="education-header">
                <img class="education-img" src="assets/img/content/dots-bg-light.svg" alt="content" />
                <h2 class="education-title">{{ 'EDUCATION.TITLE' | translate }}</h2>
            </div>
            <div class="content-detail">
                <div class="icon">
                    <fa-icon class="common-icon content-icon" [icon]="faUniversity"></fa-icon>
                </div>
                <div class="section-content">
                    <p class="paragraph university-year">2012 - 2017</p>
                    <p class="paragraph">{{ 'EDUCATION.PARAGRAPH' | translate }}</p>
                </div>
            </div>
        </div>
    </div>

    <div class="content">
        <div class="experience">
            <div class="exp-header">
                <img class="aboutme-img" src="assets/img/content/dots-bg-light.svg" alt="content" />
                <h2 class="work-title">{{ 'EXPERIENCE' | translate }}</h2>
            </div>
            <div class="exp-all">
                <div class="exp-content">
                    <div class="section-content section-experience" *ngFor="let experience of experienceList">
                        <ul>
                            <li>
                                <div class="section-exp">
                                    <h3 class="exp-title">{{experience.companyname | translate}}</h3>
                                    <h6>{{experience.totaltime | translate}}</h6>
                                </div>
                            </li>
                            <li>
                                <p class="exp-paragraph">{{experience.description | translate}}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="content content-work">
        <div class="works">
            <div class="work-header detail">
                <h2 class="work-title">{{ 'WORKS' | translate }}</h2>
            </div>

            <div class="border">
            </div>
            <div class="map">
                <img src="../../../assets/img/content/map-light.png" alt="map">
            </div>
            <div class="works-explain">
                <fa-icon class="common-icon content-icon ic-work" [icon]="faStar"></fa-icon>
                <p>{{ 'WORKS_EXPERIENCE' | translate }}</p>
            </div>
            <div class="works-description">
                <div class="list" *ngFor="let works of worksList">
                    <a class="href-works" href="{{works.url}}" target="_blank">
                        <fa-icon class="common-icon work-icon" [icon]="faCheck"></fa-icon>{{works.projectname}}
                    </a>
                </div>
            </div>
            <div class="section-link">
                <a class="href-works link" href="https://github.com/selinoykunergiz?tab=repositories"
                    target="_blank">{{ 'WORKS_DETAIL' | translate }}</a>
            </div>
            <div class="works-explain section-link-print">
                <p>{{ 'WORKS_DETAIL' | translate }} : https://github.com/selinoykunergiz?tab=repositories</p>
            </div>
        </div>
    </div>

    <div class="content c-abilities mb-100">
        <div class="abilities">
            <div class="exp-header">
                <img class="aboutme-img" src="assets/img/content/dots-bg-light.svg" alt="content" />
                <h2 class="work-title">{{ 'ABILITIES' | translate }}</h2>
            </div>
            <div class="exp-all abilities-all">
                <div class="exp-content abilities-content">
                    <div class="section-content section-abilities different" *ngFor="let abilities of abilitiesList">
                        <ul>
                            <li>
                                <div class="section-exp section-abl">
                                    <h3 class="abilities-title">{{abilities.abilitiesName}}</h3>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="content content-work">
        <div class="works">
            <div class="work-header detail">
                <h2 class="work-title">{{ 'COURSES.TITLE' | translate }}</h2>
            </div>

            <div class="border">
            </div>

            <div class="courses-list">
                <div class="courses-content">
                    <fa-icon class="common-icon content-icon ic-work" [icon]="faStar"></fa-icon>
                    <p>2017: {{ 'COURSES.JAVA' | translate }}</p>
                </div>
                <div class="courses-content">
                    <fa-icon class="common-icon content-icon ic-work" [icon]="faStar"></fa-icon>
                    <p>2017: {{ 'COURSES.ODTU' | translate }}</p>
                </div>

            </div>
        </div>
    </div>
</div>