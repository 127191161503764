<div class="home-page" [ngClass]="colorName === 'Dark Theme' ? 'dark' : 'light'">
    <div class="home-page-content">
        <div class="change-background">
            <button [ngClass]="colorName === 'Dark Theme' ? 'dark' : 'light'" (click)="changeBg()">
                {{colorName}}
            </button>
        </div>
        <div class="all-section">
            <div class="information common">
                <h1>SELIN OYKU NERGIZ</h1>
                <p>I'm a front-end developer in Ankara, who turns designs into a product.</p>
            </div>
            <div class="contact common">
                <h3>CONTACT</h3>
                <p><a href="https://github.com/selinoykunergiz/" target="_blank">GitHub</a></p>
                <p><a href="https://www.linkedin.com/in/selinoykunergiz" target="_blank">LinkedIn</a></p>
                <p><a href="mailto:selinoykunergiz@gmail.com" target="_blank">E-Mail</a></p>
                <p><a [routerLink]="['cv']">CV</a></p>
            </div>
            <div class="keywords common">
                <h3>KEYWORDS</h3>
                <p>Web & Mobile Development</p>
                <p>Responsive Development</p>
            </div>
            <div class="work common">
                <h3>WORKING FOR</h3>
                <p>Sebit Education and Information Technologies Inc.</p>
                <p>Duygu Aerospace Defense Industry Inc.</p>
                <p>Altay Software Defense Industry Inc.</p>
                <p>JotForm - Intern</p>
                <p>T.C. Ministry of Treasury and Finance - Intern</p>
                <p>General Directorate of Civil Aviation - Intern</p>
            </div>
            <div class="description common">
                <p>Let's create something together<br>
                    If you want to contact me, feel free to send me an e-mail.
                    I design & develop awesome websites and mobile applications. So, I can help with Front-end
                    Development or User Experience Design.
                </p>
            </div>
        </div>
    </div>

</div>